<template>
  <div>
    <b-card>
      <b-container>
        <b-card-title>
          <h3 class="title-card">Credit Cards</h3>
        </b-card-title>
      </b-container>
      <b-container clas="mt-1">
        <br />
      </b-container>
    </b-card>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>